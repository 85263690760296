import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { renderRoutes } from 'react-router-config'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

function Layout(props) {
  const { route } = props
  const menuList = useSelector((store) => store.common.menuList)

  const scrollToAnchor = useCallback((hash) => {
    if (hash) {
      const dom = document.getElementById(hash)
      if (dom) {
        const height = dom.offsetTop - 44
        window.scrollTo({ top: height, behavior: 'smooth' })
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  })

  const history = useHistory()

  useEffect(() => {
    history.listen((url) => {
      let { hash } = url
      if (hash) {
        hash = hash.slice(1)
      }
      setTimeout(() => {
        scrollToAnchor(hash)
      })
    })
    const hash = document.location.href.split('#')[1]
    scrollToAnchor(hash)
  }, [])

  return (
    <>
      <Header showExpand menuList={menuList} showHome />
      {renderRoutes(route.routes)}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  route: PropTypes.shape({ routes: PropTypes.arrayOf(PropTypes.object) })
    .isRequired,
}

export default React.memo(Layout)

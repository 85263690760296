import React, { memo } from 'react'
import styles from './style.module.scss'

interface Props {
  title: string
  subTitle: string
}

function PageTitle(props: Props) {
  const { title, subTitle } = props

  return (
    <div className={styles.TitleWrap}>
      <div className={styles.Title}>{title}</div>
      <div className={styles.SubTitle}>{subTitle}</div>
    </div>
  )
}

export default memo(PageTitle)

import axios, { AxiosInstance } from 'axios'

const config = {
  baseURL: process.env.VUE_APP_BASE_URL || '',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
}

const axiosInstance: AxiosInstance = axios.create(config)

axiosInstance.interceptors.request.use(
  (config) => config,
  (error) => {
    throw error
  }
)

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const res = response.data
    return res
  },
  (error) => {
    throw error
  }
)

export default axiosInstance

import produce, { enableES5 } from 'immer'
import * as actionTypes from './constants'

enableES5()

const defaultState = {
  homeNewsList: [],
}

export default (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.CHANGE_HOME_NEWS_LIST:
        draft.homeNewsList = action.data
        break
      default:
        break
    }
  })

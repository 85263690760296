// import { exact } from 'prop-types'
// import { Redirect } from 'react-router'
import React from 'react'
import { Redirect } from 'react-router-dom'
import Layout from '@/components/Layout'
import Home from '@/pages/Home'
import SocialReruit from '@/pages/SocialRecruit'
import Product from '@/pages/Product'
import Company from '@/pages/Company'
import News from '@/pages/News'
import NewsDetail from '@/pages/News/NewsDetail'
import Contact from '@/pages/Contact'

export default [
  {
    path: '/',
    component: Layout,
    routes: [
      {
        path: '/',
        exact: true,
        render: (): JSX.Element => <Redirect to="/home" />,
      },
      {
        path: '/home',
        exact: true,
        component: Home,
      },
      {
        path: '/contact',
        exact: true,
        component: Contact,
      },
      {
        path: '*',
        render: (): JSX.Element => <Redirect to="/home" />,
      },
    ],
  },
]

import { JsonObject } from '@/typings'

export function isWXBrowser(): boolean {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/micromessenger/i)) {
    return true // 是微信端
  }
  return false
}

export const getUrlParams = (): JsonObject => {
  const paramStr = window.location.search.replace('?', '')
  const obj: JsonObject = {}
  paramStr.split('&').forEach((keyValueStr) => {
    const key = keyValueStr.split('=')[0]
    const value = keyValueStr.split('=')[1]
    obj[key] = value
  })
  return obj
}

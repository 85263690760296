import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actionCreators } from '../store'

function useFetchNews() {
  const newsList = useSelector((state) => state.home.homeNewsList)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!newsList.length) {
      dispatch(actionCreators.getHomeNews())
    }
  }, [])

  return [newsList]
}

export default useFetchNews
